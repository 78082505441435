.ng-modal {
  .ng-modal-dialog {
    .ng-modal-dialog-content {
      .share-content {
        padding: 2rem 1.5rem;
        .share-heading {
          font-size: 1.4rem;
          margin-bottom: 0.5rem;
          margin-top: 0;
        }
        .share-link-textbox {
          width: 65%;
          padding: 0.3rem;
          margin-bottom: 1.5rem;
        }
        .share-button {
          padding: 0.7rem 0.7rem;
          text-align: center;
          text-decoration: none;
          cursor: pointer;
          line-height: normal;
        }
      }
    }
  }
}

.ilc-is-mobile .ilc-application-photo-page {
  .header-container {
    .action-buttons-container {
      justify-content: space-between;
    }
  }
  .app-photo-container {
    flex-direction: column;
    .image-container {
      .marker {
        padding: 7px;
        svg {
          width: 25px;
        }
      }
      .photo-short-meta-container {
        .action-buttons-container {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
    .app-photo-meta-container {
      margin-left: 0;
      margin-top: 1em;
      .details-container {
        .details-block {
          display: flex;
          flex-direction: column;
          gap: 0.75%;
          margin-bottom: 1.5em;
        }
      }
      .ilc-product-tile {
        flex-basis: 45%;
      }
    }
  }
}

.ilc-application-photo-page  {
      .details-block {
       gap: 1em; 
       display: inline-flex;
      }

    }
.ilc-application-photo-page, .ilc-application-gallery-page {
  .details-container {
    .gallery-name {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0.2rem;
      margin-top: 0;
    }

    .details-block {
      margin-bottom: 1rem;
      width: 100%; 
      .details-heading-container {
        display: flex;
        align-items: center;
        svg {
          width: 25px;
        }
      }
      .gallery-details-heading-container {
        display: flex;
        align-items: center;
        svg {
          width: 25px;
        }
      }
      .image-details-heading, .gallery-details-heading {
        margin: 0px;
        margin-bottom: 5px;
      }
      .image-details-heading {

        white-space: nowrap; 
      }
      .image-details-subheading {
        font-style: italic;
        margin: 0;
      }
      .image-details-content, .gallery-details-content {
        margin: 0px;
        flex-basis: fit-content;
        .details-content-item {
          margin: 0;
          margin-top: 8px;
        }
      }
      .image-spaces-content, .gallery-spaces-content {
        margin: 0px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
        .space-pill {
          color: white;
          background-color: #666666;
          padding: 2px 13px;
          border-radius: 17px;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .photo-credits-block {
      display: flex;
      margin-bottom:0;
      p {
        font-style: italic;
        margin-left: 0.5rem !important;
      }
    }
  }
   .featured-products-heading-container {
      display: flex;
      align-items: center;
      svg {
        width: 35px;
      }
    }
    .no-products-container {
      color: grey;
      max-height:50px;
      margin-bottom: 2rem;

      svg {
        width: 25px; 
        fill: grey;  
        vertical-align: middle; 
      }
      p {
        margin: 0px;
      }
    }
  
    .featured-products-heading-container {
      display: flex;
      align-items: center;
      svg {
        width: 35px;
      }
      .featured-products-heading {
        margin-top: 1em;
        margin-bottom: 0.5em;
      }
    }
  .featured-products-container {
    margin-top: 1rem;
   
    .featured-products-subheading {
      margin: 0;
      display: flex; 
      align-items: center;
      svg {
        width: 25px; 
        fill: grey;  

      }
    }
    
    .ilc-product-list {
      display: flex;
      flex-flow: row wrap;
      margin-left: 0;
      gap: 1.5em;
      margin-top: 1em;
      border: none;
      .photo-product-tile-container {
        flex-basis: 30%;
        min-width: 120px;
        font-size: 1.1em;
        margin-bottom: 1em;
        background: #fff; 
        .ilc-product-tile {
          border: solid 1px #dedede;
          .ilc-product-img {
            position: relative;
            .marker-container {
              top: 5%;
              left: 5%;
              .marker {
                padding: 5px;
                svg {
                  width: 20px;
                }
              }
            }
          }
          .ilc-product-name {
            min-height: 40px;
          }
          .ilc-product-actions {
            display: flex;
            border-bottom: solid 1px #dedede;
          }
          .ilc-cart-button {
            display: flex;
            font-size: 0.8rem;
            width: 50%;
            align-items: center;
            background: #000;
            color: white;
            padding-top: 5px;
            padding-bottom: 5px;
            cursor: pointer;
            svg {
              width: 15px;
              margin-right: 5px;
              margin-left: 8px;
            }
          }
          .ilc-cart-button.ilc-item-added {
            background: white;
            color: inherit;
          }
          .ilc-compare-button {
            display: flex;
            font-size: 0.8rem;
            width: 50%;
            align-items: center;
            padding-top: 5px;
            padding-bottom: 5px;
            cursor: pointer;
            svg {
              width: 17px;
              margin-right: 1px;
              margin-left: 10px;
              margin-top: 2px;
            }
          }
          .ilc-compare-button.ilc-item-added {
            background: #000;
            color: white;
          }
          .ilc-compare-button:hover {
            background: #000;
            color: white;
          }
          .ilc-cart-button:hover {
            background: #000;
            color: white;
          }
          .ilc-compare-button svg g {
            fill: inherit;
          }
          .ilc-compare-button:hover svg g {
            fill: white;
          }
          .ilc-compare-button.ilc-item-added svg g {
            fill: white;
          }
          h4 {
            font-size: 14px;
            padding: 0.7em;
            line-height: 20px;
            overflow: hidden;
            position: relative;
            margin: 0;
            padding-top: 0.1em;
            padding-bottom: 0.1em;
          }
          h4:after {
            height: 14px;
            text-align: right;
            content: "";
            width: 15%;
            position: absolute;
            bottom: 0;
            right: 0;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 1) 80%
            );
          }
          p {
            padding: 0.7em;
            font-style: italic;
            font-size: 12px;
            margin: 0;
          }
          .ilc-product-img {
            max-width: 100%;
            img {
              width: 100%;
            }
          }
          .ilc-product-description {
            padding-top: 0.1em;
            padding-bottom: 0.3em;
          }
        }
        .photos-same-product-tagged-button {
          display: flex;
          background-color: #000000;
          color: #FFFFFF;
          border: 0;
          align-items: center;
          justify-content: center;
          margin-top: 0.3em;
          border-radius: 3px;
          padding: 6px;
          cursor: pointer;
          svg {
            width: 20px;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
          p {
            margin: 0;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .ilc-application-photo-page-image {
    .marker-container-hover {
      box-shadow: 1px 1px 20px #000;
      -webkit-box-shadow: 1px 1px 20px #000;
      opacity: 1.0;
      .marker {
        background-color: #4f4f4f;
      }
    }
    .marker-container-clicked {
      box-shadow: none;
      -webkit-box-shadow: none;
      opacity: 1.0;
      .marker {
        background-color: #000000;
      }
    }
  }
  .marker-container {
    position: absolute;
    cursor: pointer;
    border-radius: 5px;
    gap: 5px;
    opacity: 0.8;
    .marker {
      display: flex;
      flex-flow: column;
      justify-content: center; 
      background-color: #000;
      padding: 10px;
      border-radius: 3px;
      svg {
        width: 30px;
        fill: #fff;
      }
    }
  }
  .header-container {
    margin-bottom: 10px;
    .action-buttons-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 0.5%;
      .ilc-action-link {
        width: 5rem;
        display: flex;
        justify-content: center;
        svg {
          padding: 0;
        }
        p {
          margin: 0;
        }
      }
      .previous-link {
        p {
          margin-left: 0.1rem;
        }
        svg {
          transform: rotate(90deg);
        }
      }
      .next-link {
        padding-right: 0;
        p {
          margin-right: 0.1rem;
        }
        svg {
          transform: rotate(270deg);
        }
      }

      
    }
  }
  .app-photo-container, .app-gallery-container {
    display: flex;
    justify-content: right;
    .image-container.is-portrait {
      flex-basis: 35%;
    }
    .image-container {
      flex-basis: 45%;
      position: relative;
      .photo-short-meta-container {
        display: flex;
        flex-wrap: wrap; 
        justify-content: space-between;
        margin-top: 1em;
        .image-title {
          font-size: 0.75rem;
          margin: 0;
          margin-bottom: 2em;
          flex-basis: 100%;
        }
        .action-buttons-container {
          justify-content: flex-start;
        }
      }
    }
    .app-photo-meta-container {
      flex-basis: 45%;
      margin-left: 100px;
    }
    .full-screen-button {
      background-color: white;
      border: 0;
      cursor: pointer;
      svg {
        fill: #000000b0;
      }
    }
  }
  .action-buttons-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 2%;

    .ilc-action-link {
      &.ilc-disabled {
        cursor: not-allowed; 
        opacity: 0.6;

      }
      display: flex;
      border: 1px solid #dedede;
      border-radius: 5px;
      padding: 0.5rem;
      align-items: center;
      color: #808080;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      cursor: pointer;
      svg {
        width: 15px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }
}