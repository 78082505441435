// is class this something that could be more general purpose..maybe renamed?
.ilc-product-similar-collection-all-link {
  font-weight: bold;
  color: #005993;
  font-size: 0.9rem;
  cursor: pointer;
}
.ilc-manufacturer-container {
  margin-top: 1.5rem;
  .ilc-manufacturer-container-side {
    display: flex;
    margin-bottom: 3rem;
    flex-direction: column;
    overflow: hidden;
    width: 21%;
    margin-right: 1.5rem;
    float: left;
    margin-left: 1.5rem;
  }
  .ilc-filtered-manufacturers-header {
    display: flex;
    align-items: center;
    .ilc-results-manufacturer-count-container {
      margin-left: auto;
      margin-right: 2rem;
      span {
        font-size: 14px;
      }
    }
  }
  .ilc-manufacturer-featured-outer-tile-container {
    display: flex;
    flex-flow: wrap;
  }
  .ilc-manufacturer-featured-products {
    display: flex;
    flex-direction: column;
    &.ilc-full-width {
      width: 100%;
    }
    .ilc-promoted-products-gallery {
      margin-top: 1rem;
      margin-bottom: 3rem;

      &.ilc-full-width {
        width: 100%;
      }
      .react-slideshow-container {
        position: relative;
        .nav:first-of-type {
          position: absolute;
          bottom: -2.3rem;
          right: 4rem;
          background-color: transparent;
          border: none;
          svg {
            width: 16px;
          }
        }
        .nav:last-of-type {
          position: absolute;
          right: 0rem;
          bottom: -2.3rem;
          background-color: transparent;
          border: none;
          svg {
            width: 16px;
          }
        }
        .ilc-banner-link {
          font-weight: bold;
          color: #005993;
          font-size: 0.9rem;
          cursor: pointer;
          text-transform: capitalize;
        }
        .ilc-featured-product-tile {
          flex-basis: 50%;
          padding: 15px;
          border: solid 1px #dedede;
          .ilc-product-name {
            font-size: 16px;
          }
          p {
            font-size: 13px;
          }
        }
      }
    }

    .ilc-quick-search-products {
      min-height: unset;
      margin-bottom: 0;
      .ilc-product-list {
        margin-left: 0;
      }
      .ilc-product-tile {
        min-width: 0;
        width: auto;
        flex-basis: 22%;
      }
    }
  }
  .ilc-banner-gallery {
    margin-top: 1rem;
    margin-bottom: 3rem;
    h3 {
      margin-bottom: 0.7rem;
    }

    &.ilc-full-width {
      width: 100%;
    }
    .react-slideshow-container {
      position: relative;
      .nav:first-of-type {
        position: absolute;
        bottom: 0rem;
        right: 4rem;
        background-color: transparent;
        border: none;
        svg {
          width: 16px;
        }
      }
      .nav:last-of-type {
        position: absolute;
        right: 0rem;
        bottom: 0rem;
        background-color: transparent;
        border: none;
        svg {
          width: 16px;
        }
      }
      .ilc-banner-link {
        font-weight: bold;
        color: #005993;
        font-size: 0.9rem;
        cursor: pointer;
        text-transform: capitalize;
      }
    }
  }

  #ilc-results-manufacturer-count {
    padding-left: 12px;
  }
}
