.ilc-application-photo-page-image {
  .marker-container {
    .marker {
      position: relative;
      .hot-spot-tool-tip {
        display: flex;
        position: absolute;
        left: 120%;
        background-color: white;
        box-shadow: 1px 1px 20px #000;
        -webkit-box-shadow: 1px 1px 20px #000;
        padding: 10px;
        width: max-content;
        opacity: 1.0;
        min-width: 220px;
        z-index: 2;
        .hot-spot-product-details {
          margin-left: 10px;
          padding: 2px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: space-between;
          p {
            margin: 0px;
            padding: 0px;
          }
          .product-manufacturer-container {
            .hot-spot-product-name {
              font-size: 18px;
              font-weight: bold;
            }
            .hot-spot-manufacturer-name {
              font-size: 15px;
              font-style: italic;
            }
          }
          .hot-spot-view-product {
            color: #B3B3B3;
            font-style: italic;
            font-size: 13px;
          }
        }
      }
    }
  }
}

#lightbox {
  z-index: 3;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  #lightbox-img {

  }
}