/* @import url('http://localhost:4300/css/ilc-product-samples.css'); */

@media only screen and (max-width: 767px) {
  /* landscape phones */

  .ilc-is-mobile #interior.ilc-header__bin,
  .ilc-is-mobile #exterior.ilc-header__bin,
  .ilc-is-mobile #building_systems.ilc-header__bin,
  .ilc-is-mobile #site_furnishings.ilc-header__bin,
  .ilc-is-mobile #urban_infrastructure.ilc-header__bin,
  .ilc-is-mobile #horticultural_hydrological.ilc-header__bin,
  .ilc-is-mobile #construction_materials.ilc-header__bin,
  .ilc-is-mobile #other.ilc-header__bin {
    width: 100%;
    border-left: solid 0px #ddd;
    min-height: 0;
  }

  .ilc-header {
    padding-top: 10px;
    margin: 0px 10px 20px 10px;
  }

  .ilc-header__bin {
    margin-top: 10px;
  }

  .ilc-tag-tabs #ilc-manufacturers-tab {
    margin-left: 0px;
  }

  .ilc-tag-tabs li.active a,
  .ilc-tag-tabs li a {
    margin-right: 0px;
  }

  .ilc-manufacturers__sort {
    margin-right: 20px;
    text-align: right;
    margin-top: 5px;
  }

  #ilc-manufacturers .ilc-manufacturer-container .manufacturers-list h3,
  #other-manufacturers .ilc-manufacturer-container .manufacturers-list h3 {
    margin-bottom: 12px;
    padding: 70px 0px 7px 14px;
    letter-spacing: 1px;
    font-size: 13px;
    margin-top: -50px;
  }

  .ilc-tag-tabs li.active a,
  .ilc-tag-tabs li.active a:hover {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 0px;
  }

  .ilc-header .ilc-tags-list .ilc-tag-container {
    width: 50%;
    margin-bottom: 3px;
    font-size: 12px;
    letter-spacing: 0.5px;
    padding-bottom: 2px;
    line-height: 40px;
  }

  #ilc-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list
    .ilc-manufacturer,
  #other-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list
    .ilc-manufacturer {
    width: 45%;
  }

  select {
    width: 100%;
  }

  .ilc-manufacturers__sort-header {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  /* portrait phones */

  .ilc-is-mobile #interior.ilc-header__bin,
  .ilc-is-mobile #exterior.ilc-header__bin,
  .ilc-is-mobile #building_systems.ilc-header__bin,
  .ilc-is-mobile #site_furnishings.ilc-header__bin,
  .ilc-is-mobile #urban_infrastructure.ilc-header__bin,
  .ilc-is-mobile #horticultural_hydrological.ilc-header__bin,
  .ilc-is-mobile #construction_materials.ilc-header__bin,
  .ilc-is-mobile #other.ilc-header__bin {
    width: 100%;
    border-left: solid 0px #ddd;
    min-height: 0;
  }

  .ilc-header {
    padding-top: 10px;
    margin: 0px 10px 20px 10px;
  }

  .ilc-header__bin {
    margin-top: 10px;
  }

  .ilc-header h2 {
    font-size: 20px;
    letter-spacing: 0.25px;
  }

  .ilc-header .ilc-tags-list .ilc-tag-container {
    width: 50%;
    margin-bottom: 3px;
    font-size: 10px;
    letter-spacing: 0px;
    padding-bottom: 2px;
    line-height: 40px;
  }

  .ilc-tag-tabs #ilc-manufacturers-tab {
    margin-left: 0px;
  }

  .ilc-tag-tabs li {
    width: 60vw;
  }

  .ilc-tag-tabs li.active a,
  .ilc-tag-tabs li.active a:hover {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 0px;
  }

  #ilc-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list
    .ilc-manufacturer,
  #other-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list
    .ilc-manufacturer {
    width: 280px;
  }

  a.ilc-clear-all {
    font-size: 12px;
    font-weight: normal;
    text-decoration: none;
    margin-left: 90px;
    color: #4a4a4a;
    position: absolute;
    top: 1650px;
  }

  select {
    width: 100%;
  }

  .ilc-manufacturers__sort-header {
    display: none;
  }
}

.react-slideshow-wrapper ul {
  list-style: none;
}

.ilc-resource-view-toggles {
  cursor: pointer;
}

.ilc-course-tile {
  margin-bottom: 25px;
}

.ilc-course-img {
  margin-bottom: 10px;
}

.ilc-course-img {
  line-height: 0;
}

.ilc-manufacturers__sort select {
  min-width: 10em;
}

.ilc-agency-pick-tooltip-description {
  max-width: 200px;
  display: none;
}
.ilc-agency-pick-tooltip.place-top {
  border-color: #666 !important;
  display: none;
}
.ilc-agency-pick-tooltip.place-top:after {
  border-top-color: #666 !important;
  display: none;
}

.ng-modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.ng-modal-overlay {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
  min-height: 150%;
}
.ng-modal-dialog {
  z-index: 10000;
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translate(-50%, 0px);
  -webkit-transform: translate(-50%, 0px);
  -moz-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  -o-transform: translate(-50%, 0px);
  background-color: #f3f3f3;
  box-shadow: 4px 4px 80px #000;
  -webkit-box-shadow: 4px 4px 80px #000;
  -moz-box-shadow: 4px 4px 80px #000;
  -ms-box-shadow: 4px 4px 80px #000;
  -o-box-shadow: 4px 4px 80px #000;
}

.ilc-sidebar-items h5 {
  font-size: 12px;
}

.ilc-sidebar p {
  font-size: 11px;
  line-height: 13px;
}

.ilc-sidebar__open .ilc-sidebar {
  width: 23%;
}

@media (max-width: 800px) {
  .ng-modal-dialog {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    width: 100%;
    top: 0px;
    left: 0px;
    margin-top: 0px;
  }
}
.ng-modal-close {
  position: absolute;
  top: 3px;
  right: 5px;
  cursor: pointer;
  font-size: 120%;
  padding: 15px;
  display: inline-block;
}
.ng-modal-close-x {
  font-weight: bold;
  font-family: Arial, sans-serif;
}
.ng-modal-title {
  font-weight: bold;
  font-size: 200%;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 7px;
  border-bottom: solid 1px #999;
}
.ilc-body-modal-on,
.ng-modal-body-on {
  overflow: hidden;
}
@media (max-width: 800px) {
  .ilc-body-modal-on .ilc-header,
  .ng-modal-body-on .ilc-header {
    display: none;
  }
}
@media (max-width: 800px) {
  .ilc-body-modal-on ul.ilc-tags-list,
  .ng-modal-body-on ul.ilc-tags-list {
    display: none;
  }
}
@media (max-width: 800px) {
  .ilc-body-modal-on
    #ilc-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list,
  .ng-modal-body-on
    #ilc-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list,
  .ilc-body-modal-on
    #other-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list,
  .ng-modal-body-on
    #other-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list {
    display: none;
  }
}
@media (max-width: 800px) {
  .ilc-body-modal-on,
  .ng-modal-body-on {
    overflow: visible;
    width: 100%;
  }
}
.ilc-slider {
  position: relative;
}
@media (max-width: 800px) {
  .ilc-slider {
    height: 50%;
  }
}
.ilc-slider .ilc-slider-main {
  height: 100%;
}
.ilc-slider .ilc-slider-main img {
  width: 100%;
  height: 100%;
}
.ilc-slider .ilc-slider-next {
  background: url("../../img/sliderarrow_right.png");
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  cursor: pointer;
  position: absolute;
  top: 48%;
  right: 0;
  background-color: rgba(38, 38, 38, 0.7);
}
.ilc-slider .ilc-slider-previous {
  background: url("../../img/sliderarrow_left.png");
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  cursor: pointer;
  position: absolute;
  top: 48%;
  right: 35px;
  background-color: rgba(38, 38, 38, 0.7);
}
.ilc-slider ul.ilc-slider-buttons {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ilc-slider ul.ilc-slider-buttons a {
  text-decoration: none;
  color: #000;
}
.ilc-slider ul.ilc-slider-buttons a:visited {
  color: #000;
}
.ilc-slider ul.ilc-slider-buttons li {
  float: left;
  margin-right: 10px;
  width: 15%;
}
.ilc-slider ul.ilc-slider-buttons li img {
  width: 100%;
}
.ilc-slider-whoa-collapse {
  position: absolute;
  bottom: 4px;
  right: 12px;
  z-index: 9090909090;
  font-size: 11px;
}
.ilc-slider-whoa-collapse a {
  color: #fff;
  text-decoration: none;
}
.ilc-slider-whoa-collapse a:visited {
  color: #fff;
  text-decoration: none;
}
.slider-down-boy {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: rgba(38, 38, 38, 0.7);
  padding: 1em 0px;
  height: 0px;
}
.slider-down-boy .ilc-manufacturer-dialog-description {
  margin-top: 0.5em;
  margin-bottom: 2em;
  color: #fff;
  text-align: justify;
  /* animation: ilc-slide-down 300ms; */
  font-size: 0px !important;
}
@keyframes box-slide-down {
  from {
    height: 20%;
  }
  to {
    height: 0px;
  }
}
@keyframes box-slide-up {
  from {
    height: 0px;
  }
  to {
    height: 30%;
  }
}
@keyframes ilc-slide-down {
  from {
    font-size: 11px;
  }
  to {
    font-size: 0px;
  }
}
.whoa {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: rgba(38, 38, 38, 0.7);
  padding-top: 6px;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 23px;
  animation: box-slide-up 500ms;
}
.whoa h3 {
  color: #fff;
  text-transform: inherit;
  font-size: 2.17em;
}
.whoa .ilc-manufacturer-dialog-description {
  margin-top: 0.5em;
  margin-bottom: 2em;
  color: #fff;
  font-size: 11px;
  text-align: justify;
  line-height: 13px;
}
@media (max-width: 700px) {
  .ilc-slider-next,
  .ilc-slider-previous {
    display: none;
  }
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
}
.loading .dot {
  position: absolute;
  border-radius: 50%;
  left: 1px;
  top: 1px;
  width: 18px;
  height: 18px;
  background: #ffffff;
  animation: spin 2.5s 0s infinite both;
}
.loading .dot2 {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #ffffff;
  animation: spin2 2.5s 0s infinite both;
}
@keyframes spin {
  0%,
  100% {
    box-shadow: 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff,
      0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff;
  }
  50% {
    transform: rotate(180deg);
  }
  25%,
  75% {
    box-shadow: 28px 0 0 #ffffff, -28px 0 0 #ffffff, 0 28px 0 #ffffff,
      0 -28px 0 #ffffff, 20px -20px 0 #ffffff, 20px 20px 0 #ffffff,
      -20px -20px 0 #ffffff, -20px 20px 0 #ffffff;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff,
      0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff;
  }
}
@keyframes spin2 {
  0%,
  100% {
    box-shadow: 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff,
      0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff;
  }
  50% {
    transform: rotate(-180deg);
  }
  25%,
  75% {
    box-shadow: 52px 0 0 #ffffff, -52px 0 0 #ffffff, 0 52px 0 #ffffff,
      0 -52px 0 #ffffff, 38px -38px 0 #ffffff, 38px 38px 0 #ffffff,
      -38px -38px 0 #ffffff, -38px 38px 0 #ffffff;
    background: transparent;
  }
  100% {
    transform: rotate(-360deg);
    box-shadow: 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff,
      0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff, 0 0 0 #ffffff;
  }
}
.ilc-manufacturer-dialiog-share-sheet {
  z-index: 499;
  background-color: #fff;
  width: 380px;
  height: 265px;
  position: absolute;
  left: 37%;
  top: 8rem;
  padding: 20px;
  line-height: 1.2;
  overflow: hidden;
  border: 1px solid #c7c7c7;
}
@media (max-width: 800px) {
  .ilc-manufacturer-dialiog-share-sheet {
    width: 200px;
    height: 300px;
  }
}
.slide_share_in {
  animation: slide 500ms;
}
@keyframes slide {
  0% {
    width: 0px;
  }
  100% {
    width: 320px;
  }
}
.ilc-manufacturer-dialog-share-sheet-body {
  position: relative;
  height: 100%;
  font-size: 150%;
}
.ilc-manufacturer-dialog-share-sheet-body h4 {
  font-size: 20px;
  margin: 0;
}
.ilc-manufacturer-dialog-share-sheet-body p {
  font-size: 11px;
  padding-top: 5px;
}
.ilc-manufacturer-dialog-share-sheet-body input {
  width: 280px;
  margin-top: 10px;
}
.ilc-manufacturer-dialog-share-sheet-body a {
  color: #000;
  text-decoration: none;
  line-height: 20px;
  font-size: 14px;
}
.ilc-manufacturer-dialog-share-sheet-body a:hover {
  color: #c0c0c0;
}
.ilc-manufacturer-dialog-share-sheet-body a:visited {
  color: #000;
  text-decoration: none;
}
.ilc-manufacturer-dialog-share-sheet-body .share-close {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
@media (max-width: 800px) {
  .ilc-manufacturer-dialog-share-sheet-body {
    font-size: 100%;
  }
  .ilc-manufacturer-dialog-share-sheet-body input {
    width: 180px;
  }
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-videos {
  margin-top: 16px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-videos hr {
  color: #c7c7c7;
  border-top-color: #c7c7c7;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-videos h3 {
  margin: 0px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
  margin-bottom: 4px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-videos ul {
  list-style: none;
  padding-left: 0;
  margin: 0px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-videos ul li {
  float: left;
  margin-left: 2%;
  position: relative;
  width: 32%;
}
.ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-videos
  ul
  li:nth-child(1) {
  margin-left: 0px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-videos ul li img {
  width: 100%;
}
@media (max-width: 700px) {
  .ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-videos ul li {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 17px;
  }
}
.ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-videos
  ul
  li
  .ilc-manufacturer-dialog-videos-play {
  width: 57px;
  height: 39px;
  background: url("../../img/play_triangle.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 20px;
  background-color: rgba(199, 199, 199, 0.7);
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0px);
  -webkit-transform: translate(-50%, 0px);
  -moz-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  -o-transform: translate(-50%, 0px);
  border-radius: 11px;
}
.ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-videos
  ul
  li
  .ilc-manufacturer-dialog-videos-play:hover {
  background-color: rgba(183, 0, 0, 0.9);
}
@media (max-width: 700px) {
  .ilc-manufacturer-dialog-body
    .ilc-manufacturer-dialog-videos
    ul
    li
    .ilc-manufacturer-dialog-videos-play {
    top: 40%;
  }
}
.ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-videos
  ul
  li
  a:hover
  .ilc-manufacturer-dialog-videos-play {
  background-color: rgba(183, 0, 0, 0.9);
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature {
  margin-top: 16px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature hr {
  color: #c7c7c7;
  border-top-color: #c7c7c7;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature h3 {
  margin: 0px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
  margin-bottom: 4px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature ul {
  list-style: none;
  padding-left: 0;
  margin: 0px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature ul li {
  float: left;
  margin-left: 2%;
  line-height: 10px;
  width: 15%;
  overflow: hidden;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature ul li a {
  color: #000;
  text-decoration: none;
  font-size: 10px;
  line-height: 10px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature ul li img {
  width: 100%;
}
.ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-literature
  ul
  li:nth-child(1) {
  margin-left: 0px;
}
@media (max-width: 700px) {
  .ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature ul li {
    width: 45%;
    margin-bottom: 20px;
    margin-left: 30%;
  }
  .ilc-manufacturer-dialog-body
    .ilc-manufacturer-dialog-literature
    ul
    li:nth-child(1) {
    margin-left: 30%;
  }
  .ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-literature ul li a {
    font-size: 14px;
    line-height: 14px;
  }
}
.hidden {
  display: none;
}
.ilc-clearfix {
  display: block;
  zoom: 1;
}
.ilc-clearfix:after {
  content: " ";
  display: table;
  clear: both;
}
.ilc-header {
  padding-bottom: 20px;
  margin: 0px 0px 10px 0px;
}
.ilc-header a.ilc-clear-all {
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  margin-left: 30px;
  margin-right: 24px;
  color: #4a4a4a;
  float: right;
}

.ilc-header .ilc-line-card-actions {
  position: relative;
  margin-right: 1rem;
}

.ilc-header .ilc-line-card-buttons {
  display: flex;
  margin-left: auto;
}

.ilc-header .ilc-line-card-actions .ilc-actions-button {
  font-size: 13px;
  cursor: pointer;
  display: flex;
  border: 1px solid #dedede;
  padding: 0rem 0.7rem;
  color: #8c8c8c;
  width: 200px;
  background: white;
  align-items: center;
  height: 39px;
}

.ilc-header .ilc-line-card-actions .ilc-actions-button svg {
  width: 15px;
  transform: rotate(180deg);
  margin-top: 2px;
  margin-left: auto;
}

.ilc-header .ilc-line-card-actions .ilc-actions-button svg path {
  fill: #8c8c8c;
}

.ilc-header .ilc-line-card-actions .ilc-actions-dropdown {
  padding: 0.6rem 0.7rem;
  border: 1px solid #dedede;
  background: white;
  position: absolute;
  width: 176px;
  z-index: 1;
}

.ilc-header .ilc-line-card-actions .ilc-actions-dropdown .ilc-clear-all {
  float: none;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  margin-bottom: 0.5rem;
  flex-direction: column;
}
.ilc-header .ilc-flex-header {
  display: flex;
  padding: 20px;
}
.ilc-header .ilc-tags-list {
  list-style: none;
  height: 100px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 10px;
  font-size: 12px;
  margin: 6px 0px 0px 9px;
}

.ilc-header .ilc-tags-list label {
  display: inline;
  margin-left: 10px;
}
.ilc-header .ilc-result-container {
  display: block;
  padding-top: 10px;
}
h1.ilc-heading-bar {
  font-size: 30px;
  color: #ffffff;
  background-color: #353535;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  margin: 0;
}
.ilc-heading-collapse {
  float: right;
  margin-right: 2em;
  line-height: 28px;
  display: none;
}
.ilc-collapse {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}
#ilc-manufacturers .nav-tabs li.active a,
#other-manufacturers .nav-tabs li.active a {
  background-color: #f7f7f7;
}
#ilc-manufacturers .ilc-manufacturer-container .manufacturers-list,
#other-manufacturers .ilc-manufacturer-container .manufacturers-list {
  background-color: #fff;
  padding: 15px 10px 30px 10px;
  border: 0px solid #ddd;
  border-top: 0;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-close-tab,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-close-tab {
  float: right;
  text-decoration: none;
  padding-right: 10px;
  font-weight: normal;
  font-size: 12px;
  color: #4a4a4a;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-close-tab:visited,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-close-tab:visited {
  color: #4a4a4a;
}
#ilc-manufacturers .ilc-manufacturer-container .manufacturers-list h3,
#other-manufacturers .ilc-manufacturer-container .manufacturers-list h3 {
  font-size: 18px;
  padding-left: 12px;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  margin-left: 10px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 15px;
  text-align: center;
  width: 190px;
  float: left;
  position: relative;
}
@media (max-width: 800px) {
  #ilc-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list
    .ilc-manufacturer,
  #other-manufacturers
    .ilc-manufacturer-container
    .manufacturers-list
    .ilc-manufacturer {
    width: 210px;
  }
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  a,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  a {
  text-decoration: none;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer:hover,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px #c0c0c0;
  -webkit-box-shadow: 0px 0px 10px #c0c0c0;
  -moz-box-shadow: 0px 0px 10px #c0c0c0;
  -ms-box-shadow: 0px 0px 10px #c0c0c0;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .manufacturer-logo,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .manufacturer-logo {
  height: 85px;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer {
  transition: all 150ms linear !important;
  border: 1px solid #ddd;
  margin-left: 14px;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-featured,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-featured {
  display: inherit;
  width: 70px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-featured-inner,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-featured-inner {
  font: bold 9px Sans-Serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 5px 10px;
  left: -7px;
  top: 9px;
  width: 115px;
  background-color: #e40303;
  color: #fff;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-featured-inner:before,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-featured-inner:before {
  left: 0;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-featured-inner:after,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-featured-inner:after {
  right: 0;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated {
  display: inherit;
  width: 70px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated-inner,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated-inner {
  font: bold 9px Sans-Serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 5px 10px;
  left: -7px;
  top: 9px;
  width: 115px;
  background-color: #18d030;
  color: #fff;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated-inner:before,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated-inner:before {
  left: 0;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated-inner:after,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated-inner:after {
  right: 0;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-new-line,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-new-line {
  display: inherit;
  width: 70px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-new-line-inner,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-new-line-inner {
  font: bold 9px Sans-Serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 5px 10px;
  left: -7px;
  top: 9px;
  width: 115px;
  background-color: #1484c4;
  color: #fff;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-new-line-inner:before,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-new-line-inner:before {
  left: 0;
}
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-new-line-inner:after,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-new-line-inner:after {
  right: 0;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated-inner,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated-inner {
  font: bold 9px Sans-Serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 5px 10px;
  left: -7px;
  top: 9px;
  width: 115px;
  background-color: #18d030;
  color: #fff;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-updated {
  display: inherit;
  width: 71px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-left: 0px;
  z-index: 9;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer:hover,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer:hover {
  box-shadow: 0px 0px 7px #c0c0c0;
  -webkit-box-shadow: 0px 0px 7px #c0c0c0;
  -moz-box-shadow: 0px 0px 7px #c0c0c0;
  -ms-box-shadow: 0px 0px 7px #c0c0c0;
}

.ilc-manufacturer-dialog-description {
  font-size: 11px;
  text-align: justify;
}
.ilc-manufacturer-dialog-tab-row {
  background-color: #353535;
  padding: 0 13px;
  padding-top: 4px;
}
.ilc-manufacturer-dialog-tab-row .ilc-manufacturer-dialog-tabs {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.ilc-manufacturer-dialog-tab-row .ilc-manufacturer-dialog-tabs li {
  float: left;
  background-color: #353535;
  margin-right: 10px;
  padding: 5px 10px;
  color: #fff;
  margin-bottom: 5px;
  font-size: 11px;
}
.ilc-manufacturer-dialog-tab-row .ilc-manufacturer-dialog-tabs li.active-tab {
  color: #353535;
  background-color: #fff;
}
.ilc-manufacturer-dialog-tab-row .ilc-manufacturer-dialog-tabs a {
  color: inherit;
  text-decoration: none;
}
.ilc-manufacturer-dialog-body {
  box-sizing: border-box;
  background-color: #f3f3f3;
  color: #353535;
  padding: 30px 100px;
  font-size: 12px;
  overflow: hidden;
  overflow-y: scroll;
  min-height: 350px;
}
@media (max-width: 800px) {
  .ilc-manufacturer-dialog-body {
    overflow: inherit;
    padding: 30px 82px;
  }
}
@media (max-width: 700px) {
  .ilc-manufacturer-dialog-body {
    padding: 15px 13px;
  }
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-row {
  width: 100%;
  height: 100%;
  min-width: 50%;
}
.ilc-manufacturer-dialog-body
  .ilc-manufacturer-row
  .ilc-manufacturer-dialog-right {
  float: left;
  width: 50%;
  margin-left: 1%;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-row .lonely {
  width: 98%;
  margin-left: 0px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-profile {
  padding-top: 0px;
}
.ilc-manufacturer-dialog-body h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin: 0px;
  text-transform: uppercase;
  line-height: 1.17em;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-connect {
  width: 100%;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-connect a#website {
  width: 32px;
  height: 32px;
  background: url("../../img/webhome.png");
  background-size: 32px 32px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-connect a#website:hover {
  background: url("../../img/webhome_hover.png");
  background-size: 32px 32px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-share {
  float: right;
  padding-top: 6px;
  font-size: 18px;
  color: #353535;
  text-decoration: none;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-companyprofile {
  float: right;
  width: 30px;
  height: 32px;
  background: url("../../img/flipmodal.png");
  background-size: 32px 32px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-companyprofile:hover {
  background: url("../../img/flipmodal_hover.png");
  background-size: 32px 32px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-phone {
  font-size: 80%;
  margin-top: 1em;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-product-types {
  float: left;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 25px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-product-types ul {
  margin: 0;
  margin-top: 5px;
  margin-left: 15px;
}
.ilc-manufacturer-dialog-body .ilc-manufacturer-dialog-product-types ul li {
  position: relative;
  float: left;
}
.ilc-manufacturer-dialog-body
  .ilc-manufacturer-dialog-product-types
  ul
  li
  span {
  margin-left: -5px;
  margin-right: 3em;
}
.ilc-manufacturer-dialog-header {
  background-color: #ffffff;
}
.ilc-manufacturer-dialog-footer {
  background-color: #353535;
  vertical-align: middle;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  margin-bottom: -1px;
}
.ilc-manufacturer-dialog-footer .footer-left {
  float: left;
  margin-left: 18px;
}
.ilc-manufacturer-dialog-footer .footer-left a {
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  vertical-align: middle;
}
.ilc-manufacturer-dialog-footer .footer-left a:visited {
  color: #fff;
}
.ilc-manufacturer-dialog-footer .footer-left a:hover {
  color: #d0d0d0;
}
.ilc-manufacturer-dialog-footer .footer-left .privacy-policy {
  border-left: 1px solid #fff;
  padding-left: 11px;
  margin-left: 10px;
}
.ilc-manufacturer-dialog-footer a.powered-by {
  float: right;
  margin-right: 18px;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  vertical-align: middle;
}
.ilc-manufacturer-dialog-footer a.powered-by:visited {
  color: #fff;
}
.ilc-manufacturer-dialog-footer a.powered-by:hover {
  color: #d0d0d0;
}
.ilc-producttype-tab-container {
  border-top: 1px solid #ddd;
}
.ilc-tag-tabs {
  margin-top: 4px;
  margin-right: 0px;
  margin-bottom: 14px;
  margin-left: 22px;
  padding-left: 0;
  padding-top: 1px;
  list-style: none;
  width: 100%;
  display: table;
  table-layout: fixed;
  background-color: #fff;
}

.ilc-tag-tabs li {
  float: left;
  margin-right: 2px;
  margin-bottom: -1px;
  display: table-cell;
}
.ilc-tag-tabs li a {
  position: relative;
  display: block;
  border: 1px solid transparent;
  text-decoration: none;
  padding: 10px 15px;
  text-align: center;
  color: #fff;
  background-color: #353535;
  font-weight: bold;
}
.ilc-tag-tabs li a:hover {
  color: #fff;
  background-color: #727272;
}
.ilc-tag-tabs li.active {
  margin-bottom: -2px;
}
.ilc-tag-tabs li.active a,
.ilc-tag-tabs li.active a:hover,
.ilc-tag-tabs li.active a:focus {
  font-weight: bold;
  color: #555;
  cursor: default;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}
.ilc-manufacturer-featured-product {
  position: relative;
  height: 50%;
  overflow: hidden;
}
.ilc-manufacturer-featured-product .ilc-manufacturer-updated {
  display: inherit;
  width: 70px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9;
}
.ilc-manufacturer-featured-product .ilc-manufacturer-updated-inner {
  font: bold 9px Sans-Serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 5px 10px;
  left: -7px;
  top: 9px;
  width: 115px;
  background-color: #18d030;
  color: #fff;
}
.ilc-manufacturer-featured-product .ilc-manufacturer-updated-inner:before {
  left: 0;
}
.ilc-manufacturer-featured-product .ilc-manufacturer-updated-inner:after {
  right: 0;
}
.ilc-manufacturer-featured-product
  .ilc-manufacturer-featured-product-description {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50%;
  background: rgba(0, 0, 0, 0.35);
  height: 100%;
  padding: 1em;
  padding-bottom: 0;
}
.ilc-manufacturer-featured-product
  .ilc-manufacturer-featured-product-description
  h3 {
  color: #fff;
  text-transform: inherit;
  font-size: 2.2em;
}
.ilc-manufacturer-featured-product
  .ilc-manufacturer-featured-product-description
  .ilc-manufacturer-featured-product-description-body {
  margin-top: 1em;
  margin-bottom: 2em;
  color: #fff;
  font-size: 11px;
  text-align: justify;
}
.ilc-manufacturer-featured-product
  .ilc-manufacturer-featured-product-description
  a {
  text-decoration: none;
  padding: 0.6em 3em;
  line-height: 1.5em;
  margin-top: 1em;
  font-style: oblique;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}
@media (max-width: 800px) {
  .ilc-manufacturer-featured-product
    .ilc-manufacturer-featured-product-description
    h3 {
    font-size: 1.8em;
    margin-bottom: 15px;
  }
  .ilc-manufacturer-featured-product
    .ilc-manufacturer-featured-product-description
    .ilc-manufacturer-featured-product-description-body {
    display: none;
  }
  .ilc-manufacturer-featured-product
    .ilc-manufacturer-featured-product-description
    a {
    padding: 0.5em 1.4em;
    font-size: 14px;
  }
}
.ilc-manufacturer-products .ilc-manufacturer-products-banner {
  display: none;
}
.ilc-manufacturer-products h3 {
  margin: 0px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
  margin-bottom: 4px;
}
.ilc-manufacturer-products li {
  position: relative;
}
.ilc-manufacturer-products li .ilc-manufacturer-products-product-overlay {
  position: absolute;
  bottom: 3px;
  left: 0px;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  color: #fff;
  font-size: 10px;
  line-height: 15px;
}
.ilc-manufacturer-products li .ilc-manufacturer-products-product-overlay span {
  padding-left: 4px;
}
.ilc-manufacturer-products
  .ilc-manufacturer-products-new-product
  .ilc-manufacturer-products-banner {
  display: inherit;
  width: 86px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
}
.ilc-manufacturer-products
  .ilc-manufacturer-products-new-product
  .ilc-manufacturer-products-banner-inner {
  font: bold 11px Sans-Serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 5px 0;
  left: 10px;
  top: 4px;
  width: 126px;
  background-color: #1ad030;
  color: #fff;
}
.ilc-manufacturer-products
  .ilc-manufacturer-products-new-product
  .ilc-manufacturer-products-banner-inner:before {
  left: 0;
}
.ilc-manufacturer-products
  .ilc-manufacturer-products-new-product
  .ilc-manufacturer-products-banner-inner:after {
  right: 0;
}
.ilc-manufacturer-products img {
  width: 100%;
  height: 100%;
}
.ilc-manufacturer-products ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ilc-manufacturer-products ul li {
  float: left;
  margin-left: 15px;
  width: 23%;
  height: 23%;
}
.ilc-manufacturer-products ul li:nth-child(1n + 5) {
  margin-top: 2.9%;
}
.ilc-manufacturer-products ul li:nth-child(1) {
  margin-left: 0px;
}
.ilc-manufacturer-products ul li:nth-child(5) {
  margin-left: 0px;
}
@media (max-width: 800px) {
  .ilc-manufacturer-products ul li {
    margin-left: 14px;
    width: 47%;
  }
  .ilc-manufacturer-products ul li:nth-child(1n + 2) {
    margin-left: 0px;
  }
  .ilc-manufacturer-products ul li:nth-child(1n + 5) {
    margin-top: 0;
  }
  .ilc-manufacturer-products ul li:nth-child(2n) {
    margin-left: 6%;
  }
  .ilc-manufacturer-products ul li:nth-child(1n + 3) {
    margin-top: 4%;
  }
}
.ilc-manufacturer-dialog-latest-products {
  margin-top: 10px;
}
.ilc-manufacturer-dialog-latest-products h3 {
  margin: 0px;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
  margin-bottom: 4px;
}
.ilc-manufacturer-dialog-latest-products .ilc-manufacturer-products-banner {
  display: none;
}
.ilc-manufacturer-dialog-latest-products li {
  position: relative;
}
.ilc-manufacturer-dialog-latest-products
  li
  .ilc-manufacturer-products-product-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  color: #fff;
  font-size: 10px;
  line-height: 15px;
}
.ilc-manufacturer-dialog-latest-products
  li
  .ilc-manufacturer-products-product-overlay
  span {
  padding-left: 4px;
}
.ilc-manufacturer-dialog-latest-products
  .ilc-manufacturer-products-new-product
  .ilc-manufacturer-products-banner {
  display: inherit;
  width: 86px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  right: 0px;
}
.ilc-manufacturer-dialog-latest-products
  .ilc-manufacturer-products-new-product
  .ilc-manufacturer-products-banner-inner {
  font: bold 11px Sans-Serif;
  color: #fff;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 5px 0;
  left: 10px;
  top: 4px;
  width: 126px;
  background-color: #1ad030;
  color: #fff;
}
.ilc-manufacturer-dialog-latest-products
  .ilc-manufacturer-products-new-product
  .ilc-manufacturer-products-banner-inner:before {
  left: 0;
}
.ilc-manufacturer-dialog-latest-products
  .ilc-manufacturer-products-new-product
  .ilc-manufacturer-products-banner-inner:after {
  right: 0;
}
.ilc-manufacturer-dialog-latest-products img {
  width: 100%;
  height: 100%;
}
.ilc-manufacturer-dialog-latest-products ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ilc-manufacturer-dialog-latest-products ul li {
  float: left;
  margin-left: 2%;
  width: 15%;
}
.ilc-manufacturer-dialog-latest-products ul li:nth-child(1) {
  margin-left: 0px;
}
@media (max-width: 800px) {
  .ilc-manufacturer-dialog-latest-products ul li {
    margin-left: 4.8%;
    width: 30%;
  }
  .ilc-manufacturer-dialog-latest-products ul li:nth-child(1),
  .ilc-manufacturer-dialog-latest-products ul li:nth-child(4n) {
    margin-left: 0;
  }
  .ilc-manufacturer-dialog-latest-products ul li:nth-child(1n + 4) {
    margin-top: 4%;
  }
}
.ilc-manufacturer-featured-container {
  display: block;
  margin-bottom: 30px;
}
.ilc-manufacturer-uhoh {
  text-align: center;
}
.ilc-manufacturer-uhoh img {
  margin-left: auto;
  margin-right: auto;
}
.ilc-manufacturer-uhoh p {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 13px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.ilc-manufacturer-uhoh a {
  padding: 10px;
  width: 120px;
  background-color: #1691d4;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.ilc-manufacturer-dialog-producttype-landing-footer {
  margin-top: 33px;
  width: 100%;
  text-align: center;
}
.ilc-manufacturer-dialog-producttype-landing-footer a {
  margin-top: 20px;
  padding: 10px;
  background-color: #1691d4;
  color: #fff;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.ilc-manufacturer-social-tower {
  margin-top: 10px;
  position: absolute;
  top: 82px;
  left: -37px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-website {
  background: url("../../img/webhome.png");
  background-size: 35px 35px;
  width: 35px;
  height: 35px;
  display: block;
  margin-bottom: 2px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-link {
  width: 35px;
  height: 35px;
  display: block;
  margin-bottom: 2px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-twitter {
  background: url("../../img/sm_twitter.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-share {
  background: url("../../img/share_email.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-terms {
  background: url("../../img/share_t_c.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-facebook {
  background: url("../../img/sm_facebook.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-linkedin {
  background: url("../../img/sm_linkedin.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-googleplus {
  background: url("../../img/sm_googleplus.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-youtube {
  background: url("../../img/sm_youtube.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-instagram {
  background: url("../../img/sm_instagram.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-pinterest {
  background: url("../../img/sm_pinterest.png");
  background-size: 35px 35px;
}

.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-quickship {
  background: url("../../img/quickship.png");
  background-size: 35px 35px;
}
.ilc-manufacturer-social-tower .ilc-manufacturer-dialog-social-bimfiles {
  background: url("../../img/BIM.png");
  background-size: 35px 35px;
}
.ilc-loading-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.ilc-featured-product-banner-img {
  width: 100%;
  height: 100%;
}

.ng-modal-dialog {
  top: 17%;
}

.ilc-manufacturer-dialog-tab-row .ilc-manufacturer-dialog-tabs li {
  float: left;
  background-color: #353535;
}

.ilc-manufacturer-products-pager {
  margin: 18px 2px 2px 0px;
  float: left;
  border-top: 1px #ddd solid;
  width: 100%;
  padding-top: 8px;
  padding-left: 0px;
}

.entry-wrap {
  margin-top: -70px !important;
}

.ilc-tag-tabs li a {
  font-weight: normal;
  border: 0px solid transparent;
  color: #fff;
  background-color: #353535;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-image-tile
  .manufacturer-description {
  width: 100%;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .manufacturer-description,
#other-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .manufacturer-description {
  background-color: #353535;
  border-top: 1px solid #ddd;
  height: 19px;
  color: #fff;
  font-size: 11px;
  padding-top: 1px;
  line-height: 1.8;
}

.ilc-tag-tabs li.active a,
.ilc-tag-tabs li.active a:hover,
.ilc-tag-tabs li.active a:focus {
  font-weight: bold;
  color: #555;
  cursor: default;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  margin-right: 0px;
}

.ilc-header a.ilc-login-button {
  color: #000;
}

.ilc-header a.ilc-create-account {
  color: #000;
}

.ilc-header a.ilc-clear-all {
  color: #000;
}

.whoa {
  width: 94%;
}

.manufacturer-logo img {
  width: 160px;
}

.ilc-header__bin {
  float: left;
}

#interior.ilc-header__bin {
  width: 40%;
}

#exterior.ilc-header__bin {
  width: 35%;
  border-left: solid 1px #000;
}

#building_systems.ilc-header__bin {
  width: 12%;
  border-left: solid 1px #000;
}

#other.ilc-header__bin {
  width: 12%;
  border-left: solid 1px #000;
}

#site_furnishings.ilc-header__bin {
  width: 24%;
}

#urban_infrastructure.ilc-header__bin {
  width: 24%;
  border-left: solid 1px #000;
}

#horticultural_hydrological.ilc-header__bin {
  width: 24%;
  border-left: solid 1px #000;
}

#construction_materials.ilc-header__bin {
  width: 24%;
  border-left: solid 1px #000;
}

.ilc-header__bin-header {
  border-bottom: solid 1px #000;
  padding-left: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 11px !important;
  color: #fff;
  background-color: #269cd7;
  padding-top: 10px;
}

.ilc-header .ilc-tags-list label {
  margin-left: 6px;
}

.ilc-header .ilc-tags-list .ilc-tag-container {
  width: 200px;
  margin-bottom: 3px;
  float: left;
}

.ilc-header h2 {
  font-size: 24px;
  margin-bottom: 13px;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer
  .ilc-manufacturer-image-tile {
  color: #fff;
  width: 300px;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-image-tile {
  width: 556px;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-image-tile
  .ilc-manufacturer-image-tile-images {
  clear: both;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-image-tile
  div {
  float: left;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-image-tile
  .manufacturer-logo {
  width: 190px;
}

#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-image-tile
  .ilc-tile-image-1
  img,
#ilc-manufacturers
  .ilc-manufacturer-container
  .manufacturers-list
  .ilc-manufacturer-image-tile
  .ilc-tile-image-1 {
  height: 86px;
  width: 366px;
}

ul.ilc-manufacturer-products-pager li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
}

.ilc-manufacturer-products-pager .active {
  width: 12px;
  height: 12px;
  margin: 0;
  margin-left: 2px;
  background-color: #000;
}

.content .ilc-tag-tabs {
  margin-bottom: 14px;
  margin-top: 20px;
}

.ilc-agency-pick-products {
  list-style: none;
  padding: 0;
}

.ilc-agency-pick-products li {
  float: left;
  width: 100px;
  margin-left: 10px;
}

.ilc-agency-pick-products li img {
  width: 100%;
}

.ilc-agency-pick {
  background-color: #ddd;
  padding-bottom: 13px;
  margin-bottom: 1.5rem;
}

.ilc-agency-pick .ilc-agency-pick-body {
  padding-bottom: 1px;
}

.ilc-agency-pick .ilc-agency-pick-header {
  background-color: #353535;
}

.ilc-agency-pick .ilc-agency-pick-header h3 {
  color: #fff;
  font-size: 14px;
}

.ilc-manufacturer-products {
  min-height: 320px;
}

.ilc-manufacturers__sort {
  margin-left: 14px;
  margin-bottom: 14px;
}

.ilc-manufacturers__sort-header {
  padding-right: 6px;
}

/*-------------------------------------      LINEMAN V4 GLOBAL STYLES      ---------------------------------*/
.ilc-compare-bar-container {
  position: fixed;
  bottom: 0;
  width: 98%;
  background: #252525;
  z-index: 10000;
  display: flex;
  .ilc-compare-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 0;
    list-style-type: none;
    width: 75%;
    margin: 0 auto;

    .ilc-product-compare-slot {
      background: #fff;
      font-size: 0.7rem;
      position: relative;
      margin-right: 0.8rem;
      display: flex;
      align-items: center;
      padding: 1rem;
      flex-basis: 19%;
      height: 4rem;
      font-weight: 700;
      button {
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 1rem;
      }
      img {
        height: 100%;
        max-width: 100%;
        display: block;
      }
      span {
        margin-left: 0.4rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: normal;
      }
    }
    .ilc-product-compare-trigger {
      background: #084184;
      color: #fff;
      font-size: 0.7rem;
      padding: 1rem 2rem;
      text-transform: uppercase;
      text-transform: uppercase;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 21px;
        margin-top: 2px;
      }
      svg g {
        fill: white;
      }
    }
    .ilc-product-compare-trigger:hover {
      background: #0072bc;
    }
    .ilc-product-compare-clear-all {
      color: #fff;
      font-size: 0.7rem;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0.3rem 0.2rem;
      width: 3.5rem;
    }
  }
}
.ilc-cart {
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 8rem;
  justify-content: center;
  font-weight: 700;
  padding: 0rem 1.2rem;
  font-size: 0.7rem;
  background: #000;
  border: none;
  color: #fff;
  cursor: pointer;
  line-height: normal;
  height: 2.4rem;
  svg {
    width: 17px;
    margin-right: 0.5rem;
  }
}

.ilc-cart-btn-wrapper {
  float: right;
  margin-right: 1em;
  display: inline;
}

.ilc-breadcrumbs {
  margin-bottom: 1.5rem;
  ul {
    margin-left: 0;
    display: flex;
    list-style: none;
    padding: 0;
    font-size: 0.8rem;
    cursor: pointer;
    flex-wrap: wrap;
    width: 75%;
    li {
      margin-bottom: 0.3rem;
      max-width: 40%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.ilc-breadcrumbs ul li:last-child {
  color: #000;
}

.ilc-breadcrumbs ul li:not(:first-child):before {
  content: "/";
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.ilc-manufacturer-filter-bar,
.ilc-search-container {
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  display: flex;
  background: #f7f7f7;
  align-items: center;
  .ilc-header-text,
  .ilc-inspirational-search-text-container {
    @media screen and (max-width: 1200px) {
      width: 97%;
      padding-bottom: 0;
      padding-top: 1.5rem;
      margin-left: 2.5rem;
    }
    display: flex;
    flex-direction: column;
    width: 43%;
    margin-left: 2rem;
    margin-right: 1.5rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    h1 {
      margin-bottom: 0;
      margin-top: 0;
      font-weight: 700;
      font-size: 32px;
      line-height: normal;
    }
    p {
      margin-top: 0.6rem;
      font-size: 17px;
      line-height: normal;
    }
  }
  .ilc-header-filter-section {
    width: 100%;
  }
  .ilc-quick-search-filters {
    @media screen and (max-width: 1200px) {
      margin-left: 1rem;
      margin-top: 1rem;
    }
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    .ilc-filter-toggle-container {
      width: 17rem;
      .ilc-line-card-filter-toggle-button {
        background: white;
        padding: 0.6rem;
        position: relative;
        cursor: pointer;
        border: 1px solid #dedede;
        height: 38px;
        padding-left: 0.7rem;
        padding-right: 1.5rem;
        text-align: left;
        line-height: normal;
        font-size: 0.8rem;
        width: 100%;
        border-radius: 5px;

        .ilc-toggle-icon {
          position: absolute;
          top: 12px;
          right: 9px;
          svg {
            width: 15px;
            transform: rotate(180deg);
            path {
              fill: #707070;
            }
          }
        }
      }
    }
    .ilc-input-container {
      @media screen and (max-width: 1200px) {
        width: 50%;
      }
      width: 28rem;
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 1rem;
      .ilc-searchbar-v2 {
        border-radius: 50px;
        border: 1px solid #dedede;
        height: 38px;
        padding: 1rem 1rem 1rem 2rem;
        text-align: left;
        width: 100%;
        line-height: normal;
        font-size: 0.85rem;
        margin-bottom: 0;
      }
      .ilc-searchbar-icon {
        position: absolute;
        top: 12px;
        left: 10px;
        svg {
          width: 15px;
          transform: rotate(100deg);
          font-weight: 700;
        }
        svg path {
          fill: #707070;
        }
      }
    }
  }
  .ilc-filter-modal-overlay {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 500; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
    transition: 0.5s;

    .ilc-filter-modal {
      position: absolute;
      background: #0f0f0f;
      width: 91%;
      margin-top: 10%;
      left: 50%;
      transform: translate(-50%, 0);
      max-width: 100vw;
      opacity: 0.9;
      @media screen and (max-width: 1024px) {
        width: 100%;
        left: 0;
        transform: unset;
        max-width: 100vw;
      }

      .ilc-modal-top {
        padding: 1em;
        width: 100%;
        .ilc-filter-modal-close {
          float: right;
          color: white;
          background: none;
          border: none;
          cursor: pointer;
          font-weight: 700;
        }
      }

      .ilc-filter-modal-content {
        padding: 1.3em;
        color: white;
        .ilc-dropdown-bins {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 1em;
          @media screen and (min-width: 601px) and (max-width: 1299px) {
            flex-direction: column;
          }
          @media screen and (max-width: 600px) {
            flex-direction: column;
            padding: 1em 0px 0px 0px;
          }
          .ilc-mobile-filter-accordion-container {
            @media screen and (min-width: 1300px) {
              flex-basis: 30%;
            }
            .ilc-mobile-accordion-toggle {
              display: none;
            }
          }
          &.ilc-with-programs-bin .ilc-mobile-filter-accordion-container {
            @media screen and (min-width: 1300px) {
              flex-basis: 23%;
            }
          }

          .ilc-dropdown-ilc-header__bin {
            padding-right: 1em;
            .ilc-dropdown-ilc-header__bin-header {
              background: #40404a;
              color: white;
              padding: 0.6em;
              font-size: 0.9em;
            }

            .ilc-dropdown-tags-list {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              list-style-type: none;
              padding-left: 0;
              margin-left: 0;
              margin-top: 1em;
              @media screen and (min-width: 601px) {
                height: 500px;
              }
              @media screen and (min-width: 601px) and (max-width: 1299px) {
                height: 325px;
                align-content: start;
              }
              @media screen and (max-width: 600px) {
                height: 550px;
              }
              .ilc-dropdown-tag-container {
                @media screen and (min-width: 601px) and (max-width: 1299px) {
                  width: 33%;
                }
                height: 25px;
                .ilc-tag-value {
                  background: none;
                  border: none;
                  cursor: pointer;
                  padding: 0em 0em 0em 0.3em;
                  font-size: 0.9em;
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*-------------------------------------      GENERAL SIDEBAR STYLES       ---------------------------------*/

.ilc-sidebar p {
  font-size: 12px;
  line-height: 14px;
}

.ilc-sidebar__closed .ilc-sidebar {
  display: block;
}

.ilc-sidebar__closed .ilc-sidebar-items {
  display: none;
}

.ilc-sidebar__open .ilc-sidebar-items {
  display: block;
}
.ilc-sidebar__open .ilc-sidebar-body {
  padding-top: 114px;
}

.ilc-sidebar__open .ilc-sidebar {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 30%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
  color: #fff;
}

.ilc-sidebar__open .ilc-header {
  margin-right: 30%; /* Same as the width of the sidebar */
  padding: 0px 10px;
}
.ilc-sidebar__open .ilc-body {
  margin-right: 30%; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

@media (min-width: 1025px) {
  .ilc-layout {
    margin-left: 25px;
    margin-right: 25px;
  }
}

/*-------------------------------------      SIDEBAR STYLES (ORIGINALLY FROM CAL)      ---------------------------------*/

.ilc-sidebar-body a.ilc-sidebar-open-anchor {
  color: #fff;
  background-color: #000;
  float: right;
  margin-left: 15px;
  margin-top: 15px;
  height: 75px;
  padding: 5px;
  display: none;
}

.ilc-sidebar-open-button-icon {
  width: 15px;
  height: 15px;
  border-width: 4px 4px 0 0;
  border-style: solid;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  box-sizing: border-box;
  transform: rotate(-135deg);
  background-color: black;
}

.ilc-sidebar-open-button-icon:before {
  content: "";
  box-sizing: "border-box";
  right: 0;
  top: -3px;
  position: absolute;
  height: 4px;
  box-shadow: inset 0 0 0 32px;
  transform: rotate(-45deg);
  width: 23px;
  transform-origin: right top;
}

.ilc-sidebar-open-button {
  margin-top: 5px;
  transform: rotate(90deg);
}

.ilc-sidebar__open .ilc-sidebar-open-button {
  visibility: hidden;
}

.ilc-sidebar__open a.ilc-sidebar-open-anchor {
  background-color: transparent;
  transform: rotate(180deg);
  height: 0px;
  margin-top: 25px;
}

.ilc-sidebar__open .ilc-sidebar {
  background-color: #353535;
  box-shadow: -3px 0px 8px #a0a0a0;
}

.ilc-sidebar-items h4 {
  color: #fff;
  background: #262626;
  padding: 2px 7px;
  margin-bottom: 0px;
  margin-top: -11px;
  padding-left: 14px;
}

.ilc-sidebar-items h5 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
}

.ilc-sidebar__open .ilc-sidebar-items {
  display: block;
  padding: 0px 0px 0px 0px;
}

.manufacturer_name_subtitle {
  font-style: italic;
  font-size: 14px;
  color: #bbb;
  margin-bottom: 4px;
}

/*-------------------------------------      FEATURED PRODUCT SIDEBAR STYLES         ---------------------------------*/

.ilc-sidebar-featured-products li {
  min-height: 130px;
  border-bottom: solid 1px #7b7b7b;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 15px;
  transition-duration: 0.3s;
}

.ilc-sidebar-featured-products li:hover {
  cursor: pointer;
  background: #505050;
}

.ilc-sidebar-featured-products img {
  background: #fff;
  display: inline;
  float: left;
  margin-right: 13px;
  width: 123px;
}

.ilc-sidebar-featured-products a {
  color: #fff;
}

.ilc-sidebar-featured-products h4 {
  color: #d9ca05;
  background: transparent;
}

/*-------------------------------------      FEATURED NEWS SIDEBAR STYLES         ---------------------------------*/

.ilc-sidebar-news a {
  color: white;
}

.ilc-sidebar-news span {
  color: #ffffff;
}

.ilc-sidebar-news h5 {
  font-size: 16px;
  text-transform: uppercase;
}

.ilc-sidebar-news img {
  width: 60%;
  margin-right: 10px;
}

.ilc-sidebar-news li {
  border-bottom: solid 1px #7b7b7b;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  transition-duration: 0.3s;
}

.ilc-sidebar-news li:hover {
  cursor: pointer;
  background: #505050;
}

.ilc-sidebar-news .subtitle {
  margin-bottom: 7px;
  font-style: italic;
  font-size: 11px;
  color: #e0dfdf;
}

span.read-more {
  background: #000000;
  color: #fff;
  padding: 5px 12px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: bold;
  display: block;
  margin-top: 15px;
  transition-duration: 0.3s;
}

span:hover.read-more {
  background: #262626;
}

/*-------------------------------------      FEATURED BANNERS SIDEBAR STYLES         ---------------------------------*/

.ilc-sidebar-banners a {
  color: #fff;
}

ilc-sidebar-banners h5 {
  font-size: 16px;
  text-transform: uppercase;
}

.ilc-sidebar-banners img {
  width: 70%;
  background: #ddd;
  margin-bottom: 8px;
}

.ilc-sidebar-banners li {
  padding-right: 15px;
  border-bottom: solid 1px #7b7b7b;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  transition-duration: 0.3s;
}

.ilc-sidebar-banners li:hover {
  cursor: pointer;
  background: #505050;
}

/*-------------------------------------     Slideshow styles     ---------------------------------*/
.react-slideshow-wrapper.slide {
  width: 100%;
  overflow: hidden;
}

.react-slideshow-wrapper .images-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.react-slideshow-wrapper .images-wrap * {
  max-width: 100%;
}
.react-slideshow-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.react-slideshow-container .nav {
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  width: 30px;
  z-index: 10;
  line-height: 30px;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 15px;
}

.react-slideshow-container .nav:hover {
  background: white;
  color: #666;
}

.react-slideshow-container .nav.disabled {
  cursor: not-allowed;
}

.react-slideshow-container .nav.disabled:hover {
  background: rgba(255, 255, 255, 0.6);
  color: #fff;
}

.react-slideshow-container .nav:first-of-type {
  margin-right: -30px;
  margin-left: 10px;
  background-color: #313131c9;
}

.react-slideshow-container .nav:last-of-type {
  margin-left: -30px;
  margin-right: 10px;
  background-color: #313131c9;
}
.react-slideshow-container + div.indicators {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}

.react-slideshow-container + div.indicators > div {
  width: 7px;
  height: 7px;
  margin-right: 10px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
}

.react-slideshow-container + div.indicators > div:hover {
  background: #666;
}

.react-slideshow-container + div.indicators > div.active {
  background: #000;
}

.react-slideshow-container + div.indicators > div:first-of-type {
  margin-right: 0;
}

/*-------------------------------------      Responsive Breakpoints       ---------------------------------*/

/* Regular Screen Adjustments for Fluid Column Scaling + Wrapping */

#interior.ilc-header__bin {
  width: 40%;
}

#exterior.ilc-header__bin {
  width: 32%;
}

#building_systems.ilc-header__bin {
  width: 14%;
}

#other.ilc-header__bin {
  width: 13%;
}

@media (min-width: 1300px) {
  .ng-modal-dialog {
    width: 800px;
  }
}

@media only screen and (max-width: 1300px) {
  /* small desktops */

  /*  #interior.ilc-header__bin, #exterior.ilc-header__bin  {    width: 32%;    }

       #building_systems.ilc-header__bin {   width: 35%;   }

       #other.ilc-header__bin    {     width: 35%;     } */

  #interior.ilc-header__bin,
  #exterior.ilc-header__bin {
    width: 36%;
  }

  #site_furnishings.ilc-header__bin,
  #urban_infrastructure.ilc-header__bin,
  #horticultural_hydrological.ilc-header__bin,
  #construction_materials.ilc-header__bin {
    width: 49%;
  }

  #building_systems.ilc-header__bin {
    width: 26%;
  }

  #other.ilc-header__bin {
    width: 26%;
  }

  .ilc-header__bin {
    margin-top: 10px;
  }
}

@media (max-width: 1000px) {
  .ilc-sidebar-featured-products img {
    width: 65px;
  }
}

.ilc-legal-doc-links {
  margin-top: 4rem;
  min-height: 40px;
  float: right;
  span {
    font-size: 10px;
    color: #ababab;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
